export default `
<i>Политика конфиденциальности определяет политику Компании в отношении обработки Данных Пользователей при использовании ими Сервиса, а также содержит сведения о реализуемых требованиях к защите персональных данных Пользователей.</i>

1. <b>ТЕРМИНЫ</b>

1.1. <b>Компания</b> – юридическое лицо, предоставляющее Пользователям Сервис. Наименование Компании размещено на Ресурсе.

1.2. <b>Ресурс</b>– сайт, программное обеспечение и/или иной интернет-ресурс Компании.

1.3. <b>Сервис</b> – функционал Ресурса, предоставляемый Компанией, в том числе предоставление доступа к Ресурсу или его части, Контенту и иным материалам.

1.4. <b>Пользователь</b> – физическое лицо, использующее Сервис.

1.5. <b>Данные</b> – персональные данные Пользователя, а также иная пользовательская информация.

2. <b>ДЕЙСТВИЕ ПОЛИТИКИ</b>

2.1. Используя Сервис (включая осуществление доступа к Ресурсу), Пользователь соглашается с условиями обработки Данных Компанией, описанными в настоящей Политике конфиденциальности (Политика). В случае несогласия Пользователя с такими условиями, использование Сервиса, в том числе осуществление доступа к Ресурсу, должно быть прекращено.

2.2. Политика может быть изменена Компанией. Измененная Политика размещается на Ресурсе. Используя Сервис после изменения Политики, Пользователь соглашается с измененными условиями обработки Данных.

3. <b>ЦЕЛЬ И УСЛОВИЯ ОБРАБОТКИ</b>

3.1. Компания обрабатывает Данные с целью предоставления Сервиса, включая:

<ul>
  <li>обеспечение функционирования Сервиса;</li>

  <li>улучшение качества и удобства использования Сервиса;</li>

  <li>создание и развитие существующих и новых продуктов Компании и ее партнеров;</li>

  <li>персонализация контента и иных предложений;</li>

  <li>получение Пользователем сообщений;</li>

  <li>продвижение Ресурсов, продуктов и/или услуг Компании и ее партнеров;</li>

  <li>прием обращений Пользователей и предоставление ответов на указанные обращения;</li>

  <li>проведение статистических и иных исследований использования Сервиса.</li>
</ul>

<p>В указанной цели Компания обрабатывает следующие Данные: адрес электронной почты; сведения, собираемые посредством метрических программ; сведения о пользовательском устройстве; cookies, идентификатор пользователя, данные об использовании ресурса; сетевой адрес.</p>

3.2. Для цели, указанной в п. 3.1. Политики, применимы следующие условия обработки:

3.2.1. Компанией не обрабатываются биометрические и специальные категории персональных данных Пользователей. Обрабатываемые Компанией персональные данные относятся к иным категориям персональных данных.

3.2.2. Перечень действий с Данными: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ), обезличивание, блокирование, удаление и уничтожение. Указанные действия могут быть
осуществлены как неавтоматизированным, так и автоматизированным способами.

3.2.3. Срок обработки и хранения Данных: до достижения цели обработки, до истечения срока действия согласия или до отзыва согласия (если отсутствуют иные основания для обработки таких Данных), в зависимости от того, какое событие наступило раньше.

3.3. Компания обязуется обеспечить запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных Пользователей с использованием баз данных, находящихся на территории РФ.

3.4. Для предоставления соответствующего Сервиса Пользователю Компания при наличии правового основания может осуществлять передачу (предоставление, доступ) Данных для их последующей обработки провайдерам услуг и партнёрам, указанным в соответствующих документах Компании, размещенных на Ресурсе.

4.<b>ОСНОВАНИЯ ОБРАБОТКИ</b>

4.1. Правовыми основаниями обработки Данных Компанией являются в том числе:

<ul>
  <li>согласие Пользователя;</li>

  <li>исполнение, а также заключение Условий использования или иных соглашений с Пользователем;</li>

  <li>осуществление прав и законных интересов Компании или третьих лиц, если при этом не нарушаются права и свободы Пользователя;</li>

  <li>обработка в статистических или иных исследовательских целях, при условии обязательного обезличивания Данных;</li>

  <li>необходимость обработки для достижения целей, предусмотренных законом, а также выполнение обязанностей, возложенных на Компанию законодательством.</li>
</ul>

5. <b>ПРАВА ПОЛЬЗОВАТЕЛЯ</b>

<p>Пользователь вправе:</p>

<ul>
  <li>получать информацию, касающуюся обработки своих Данных;</li>

  <li>требовать от Компании уточнения его Данных, их блокирования или уничтожения в случае, если они неполные, устаревшие, неточные, незаконно полученные или не являются необходимыми для заявленной цели обработки;</li>

  <li>отозвать согласие на обработку своих Данных, направив Компании заявление</li>
</ul>


6. <b>УСЛОВИЯ ИСПОЛЬЗОВАНИЯ COOKIES</b>

6.1. Для исполнения Условий использования и в рамках цели, указанной в п. 3.1. Политики, Компания осуществляет обработку сookies в том числе для обеспечения функционирования Сервиса; персонализации Контента и иных предложений; проведения статистических и иных исследований использования Сервиса. Пользователь может просмотреть срок действия cookies, а также запретить их сохранение в настройках своего браузера.

6.2. Сookies и иные сведения могут обрабатываться в том числе с использованием метрических программ, в частности, Статистика от SberAds, Яндекс.Метрика, Рейтинг Mail.ru, LiveInternet, Mediascope.

7. <b>ПОРЯДОК УНИЧТОЖЕНИЯ </b>

7.1. В отношении Данных, обрабатываемых Компанией в указанной в п. 3.1. Политики цели, действует единый порядок их уничтожения в соответствии с законодательством РФ и локальными нормативными актами Компании.

7.2. Данные подлежат уничтожению Компанией, в частности, если достигнута цель обработки или Пользователем отозвано согласие на их обработку (и отсутствуют иные основания для обработки таких Данных), либо выявлен факт их неправомерной обработки.

7.3. В случае достижения цели обработки Данных Компания прекращает их обработку и уничтожает их в срок, не превышающий 30 дней с даты достижения цели обработки. В случае отзыва Пользователем согласия на обработку его Данных, Компания прекращает их обработку и в случае, если сохранение Данных более не требуется для цели обработки, уничтожает их в срок, не превышающий 30 дней с даты поступления указанного отзыва.

7.4. В случае выявления неправомерной обработки персональных данных, осуществляемой Компанией, Компания в срок, не превышающий 3 рабочих дней с даты этого выявления, прекращает такую обработку. В случае, если обеспечить правомерность такой обработки невозможно, Компания в срок, не превышающий 10 рабочих дней с даты выявления неправомерной обработки, уничтожает такие Данные. При этом Компания уведомляет Пользователя об устранении допущенных нарушений или об уничтожении Данных.

8.<b>СВЕДЕНИЯ О РЕАЛИЗУЕМЫХ ТРЕБОВАНИЯХ К ЗАЩИТЕ</b>

8.1. Компания обязуется обеспечивать конфиденциальность персональных данных Пользователей.

8.2. Компания обеспечивает безопасность персональных данных Пользователей посредством реализации правовых, организационных и технических мер.

8.2.1. Правовые меры, принимаемые Компанией, включают:

<ul>
  <li>издание Компанией документов, определяющих политику в отношении обработки персональных данных, локальных актов по вопросам обработки персональных данных, а также локальных актов, устанавливающих процедуры, направленные на предотвращение и выявление нарушений законодательства РФ, устранение последствий таких нарушений.</li>
</ul>

8.2.2. Организационные меры, принимаемые Компанией, включают:

<ul>
  <li>обеспечение неограниченного доступа к Политике, к сведениям о реализуемых требованиях к защите персональных данных;</li>

  <li>осуществление внутреннего контроля и (или) аудита соответствия обработки персональных данных Федеральному закону от 27.07.2006 N 152-ФЗ «О персональных данных» (Закон) и принятым в соответствии с ним нормативным правовым актам, требованиям к защите персональных данных, политике Компании в отношении обработки персональных данных, локальным актам Компании;</li>

  <li>оценка вреда, который может быть причинен субъектам персональных данных в случае нарушения Закона, соотношение указанного вреда и принимаемых Компанией мер, направленных на обеспечение выполнения обязанностей, предусмотренных Законом;</li>

  <li>ознакомление работников Компании, непосредственно осуществляющих обработку персональных данных, с положениями законодательства РФ о персональных данных, в том числе требованиями к защите персональных данных, документами, определяющими политику Компании в отношении обработки персональных данных, локальными актами по вопросам обработки персональных данных, и (или) обучение указанных работников;</li>

  <li>назначение лица, ответственного за организацию обработки персональных данных;</li>

  <li>назначение лица, ответственного за обеспечение безопасности персональных данных в информационных системах персональных данных (ИСПДн);</li>

  <li>утверждение руководителем Компании документа, определяющего перечень лиц, доступ которых к персональным данным, обрабатываемым в ИСПДн, необходим для выполнения ими служебных (трудовых) обязанностей;</li>

  <li>обеспечение сохранности носителей персональных данных;</li>

  <li>учет машинных носителей персональных данных;</li>

  <li>определение угроз безопасности персональных данных при их обработке в ИСПДн;</li>

  <li>организация контролируемой зоны, в пределах которой постоянно размещаются стационарные технические средства, обрабатывающие информацию, и средства защиты информации, а также средства обеспечения функционирования;</li>

  <li>организация режима обеспечения безопасности помещений, в которых размещена ИСПДн, препятствующего возможности неконтролируемого проникновения или пребывания в этих помещениях лиц, не имеющих права доступа в эти помещения.</li>
</ul>


8.2.3. Технические меры, принимаемые Компанией, включают:
<ul>
  <li>использование средств защиты информации, прошедших процедуру оценки соответствия требованиям законодательства РФ в области обеспечения безопасности информации, в случае, когда применение таких средств необходимо для нейтрализации актуальных угроз;</li>

  <li>оценку эффективности принимаемых мер по обеспечению безопасности персональных данных до ввода в эксплуатацию ИСПДн;</li>

  <li>установление правил доступа к персональным данным, обрабатываемым в ИСПДн, а также обеспечением регистрации и учета всех действий, совершаемых с персональными данными в ИСПДн;</li>

  <li>обнаружение фактов несанкционированного доступа к персональным данным и принятием мер, в том числе мер по обнаружению, предупреждению и ликвидации последствий компьютерных атак на ИСПДн и по реагированию на компьютерные инциденты в них;</li>

  <li>восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</li>

  <li>контроль за принимаемыми мерами по обеспечению безопасности персональных данных и уровня защищенности ИСПДн.</li>
</ul>

9. <b>ИНОЕ</b>

9.1. Пользователь несет ответственность за правомерность предоставления, актуальность и достоверность данных Пользователя и иных лиц, которые Пользователь предоставляет Компании.

9.2. Компания рассматривает обращения, связанные с Политикой, включая запросы Пользователей относительно использования их Данных по адресу: 117105, Россия, г. Москва, Варшавское шоссе, д. 9, стр. 1.

<i>Редакция от 6 декабря 2024</i>
`;
