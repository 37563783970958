import { Route } from 'core/libs/router';
import { Helmet } from 'core/libs/helmet';

import configResolver from './shared-app-config';

import resolve from 'core/resolver/resolve';
import withPageHocs from 'core/components/withPageHocs';

import App from 'core/components/App';
import BaseRoutes from 'core/components/BaseRoutes';

import Layout from 'site/components/Layout';

import Main from 'site/pages/main';
import PVP from 'site/pages/pvp';
// если появятся другие страницы, то можно будет переименовать
import ChineseCars from 'site/pages/chinese-cars';

import Topic from 'site/pages/topic';
import Rubric from 'site/pages/rubric';
import Author from 'site/pages/author';
import Search from 'site/pages/search';
import About from 'site/pages/about';
import Legal from 'site/pages/legal';
import NotFound from 'site/pages/notFound';
// import News from 'site/pages/news';
// import Tag from 'site/pages/tag';

import { topicSlug, basicSlug } from 'site/constants';

import './global.styl';
import styles from './index.styl';

const FakeNotFound = withPageHocs(resolve('asdf', ({ renderError }) => {
  var err = new Error('Not found');
  err.statusCode = 404;

  return Promise.reject(err)
    .catch(renderError);
}))(() => null);

export default function Site() {
  /**
   * Конфиг приложения нужно резолвить на каждую перерисовку чтоб при SSR
   * правильно резолвились переменные окружения.
   */
  const config = configResolver();

  // TODO: Поправить ошибку с yaContextCb в AdFox-компоненте
  return (
    <div className={styles.horScrollFixer}>
      <Helmet>
        <script>{'window.yaContextCb=window.yaContextCb||[];'}</script>
        <script src='https://yandex.ru/ads/system/context.js' async />
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>
      <App {...config}>
        <Layout>
          <BaseRoutes
            main={Main}
            search={Search}
            about={About}
            notFound={NotFound}
          >
            {/* Страница автора */}
            <Route
              path={`/authors/:level_2(${basicSlug})/:pageSlug(page-\\d+)?`}
              component={Author}
              exact
            />
            <Route
              path='/legal/:level2(terms-of-use|privacy|mailing-rules|terms-of-use-2)?'
              component={Legal}
              exact
            />
            <Route
              path='/:rubricSlug(want-to-buy|ownership|want-to-sell)'
              component={PVP}
              exact
            />
            <Route
              path={'/:tagSlug(chinese-cars)/:pageSlug(page-\\d+)?'}
              component={ChineseCars}
              exact
            />
            {/* топик типа новость */}
            <Route
              path='(/preview)?/:topic_link(news/[a-z0-9-_.]+-\d{2}-\d{2}-\d{4})'
              component={Topic}
              exact
            />
            {/* Новости главная */}
            <Route
              path='/news/:pageSlug(page-\d*)?'
              component={FakeNotFound}
              exact
            />
            {/* теги */}
            <Route
              path={`/news/:tagSlug(${basicSlug})/:pageSlug(page-\\d+)?`}
              component={FakeNotFound}
              exact
            />
            {/* рубрики */}
            <Route
              path='/:rubricSlug/:pageSlug(page-\d*)?'
              component={Rubric}
              exact
            />
            {/* топик */}
            <Route
              path={`(/preview)?/:topic_link(${topicSlug})`}
              component={Topic}
              exact
            />
          </BaseRoutes>
        </Layout>
      </App>
    </div>
  );
}
