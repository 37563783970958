export default `
<i>Условия рассылок определяют условия, на которых Компания предоставляет Сервис, позволяющий Пользователям получать Сообщения.</i>

1. <b>ТЕРМИНЫ</b>

1.1. <b>Компания</b> – юридическое лицо, предоставляющее Пользователям Сервис. Наименование Компании размещено на Ресурсе.

1.2. <b>Сообщения</b> – информационные сообщения, в том числе новостного, рекламного или иного характера, связанные с Ресурсом и/или тематикой Ресурса, а также с товарами/услугами Компании и/или ее партнеров.

1.3. <b>Подписка на рассылку</b> – действия Пользователя при использовании Сервиса, направленные на систематическое получение Сообщений от Компании, в том числе внесение Пользователем контактных данных в соответствующую форму.

2. <b>ДЕЙСТВИЕ УСЛОВИЙ</b>

2.1. Условия рассылок (Условия) являются частью Условий использования. Условия заключаются в соответствии с нормами законодательства РФ, регулирующими принятие публичной оферты конклюдентными действиями. Подписка на рассылку означает полный и безоговорочный акцепт Условий Пользователем. В случае несогласия Пользователя с каким-либо из положений Условий, Пользователь обязан воздержаться от Подписки на рассылку.

2.2. Отношения Пользователя и Компании, возникающие в связи с Подпиской на рассылку, регулируются законодательством РФ.

2.3. Условия могут быть изменены Компанией в одностороннем внесудебном порядке. Измененные Условия размещаются на Ресурсе. Продолжая получать Сообщения после изменения Условий, Пользователь принимает Условия полностью и безоговорочно в измененном виде.

3. <b>УСЛОВИЯ РАССЫЛКИ СООБЩЕНИЙ</b>

3.1. Принимая Условия, Пользователь дает согласие Компании на получение Сообщений от Компании по адресу электронной почты, указанному Пользователем при Подписке на рассылку. Пользователь осознает и соглашается, что Сообщения могут содержать рекламу. Пользователь вправе в любое время отказаться от получения Сообщений путем перехода по соответствующей гиперссылке, содержащейся в Сообщении.

3.2. Для осуществления рассылки Компании необходима обработка адреса электронной почты Пользователя. Компания осуществляет указанную обработку в соответствии с Политикой конфиденциальности Компании.

3.3. Принимая Условия, Пользователь дает согласие Компании на обработку (сбор, запись, систематизацию, накопление, хранение, уточнение, извлечение, использование, обезличивание, блокирование, удаление и уничтожение) предоставленных персональных данных для получения Пользователем сообщений и продвижения Ресурсов, продуктов и/или услуг Компании и ее партнеров. Данное согласие действует с момента предоставления данных Пользователем и до достижения цели их обработки и может быть отозвано путем направления Компании письменного заявления.

3.4. Пользователь гарантирует Компании, что Подписка на рассылку осуществляется непосредственно Пользователем, а также, что адрес электронной почты, указанный Пользователем при Подписке на рассылку, является корректным и принадлежит Пользователю. Ответственность за правомерность предоставления данных при Подписке на рассылку и их достоверность несет исключительно Пользователь.

<i>Редакция от 6 декабря 2024</i>
`;
