export default `
<i>Условия использования определяют условия, на которых Компания предоставляет Сервис. </i>

1. <b>ТЕРМИНЫ</b>

1.1. <b>Компания</b> – юридическое лицо, предоставляющее Пользователям Сервис. Наименование Компании размещено на Ресурсе.

1.2. <b>Ресурс</b> – сайт, программное обеспечение и/или иной интернет-ресурс Компании.

1.3. <b>Сервис</b> – функционал Ресурса, предоставляемый Компанией, в том числе предоставление доступа к Ресурсу или его части, Контенту и иным материалам.

1.4. <b>Пользователь</b> – физическое лицо, использующее Сервис.

1.5. <b>Контент</b> – дизайн, графика, изображения, фотографии, тексты, статьи, видео, музыка, песни, программное обеспечение, базы данных, товарные знаки, логотипы, любые иные результаты интеллектуальной деятельности и средства индивидуализации, размещенные или размещаемые на Ресурсе.

1.6. <b>Условия</b> – настоящие Условия использования, являющиеся соглашением между Компанией и Пользователем и устанавливающие права и обязанности по использованию Сервиса.

1.7. <b>Правила применения рекомендательных технологий</b> – неотъемлемая часть Условий, определяющая общие условия и правила применения информационных технологий предоставления информации на основе сбора, систематизации и анализа сведений, относящихся к предпочтениям Пользователей.

1.8. <b>Условия рассылок</b> – часть Условий, содержащая условия, на которых Компания предоставляет Сервис, позволяющий Пользователям получать Сообщения.

1.9. <b>Политика конфиденциальности</b> – документ, определяющий политику Компании в отношении обработки данных Пользователей при использовании ими Сервиса, а также содержащий сведения о реализуемых требованиях к защите персональных данных Пользователей.

1.10. <b>Документы</b> – совместно Условия, Условия рассылок и Политика конфиденциальности, а также пользовательские соглашения отдельных Сервисов и иные документы, регулирующие отношения между Компанией и Пользователем по предоставлению и использованию Сервиса, размещенные на Ресурсе.

2. <b>ДЕЙСТВИЕ УСЛОВИЙ</b>

2.1. Условия заключаются в соответствии с нормами законодательства РФ, регулирующими принятие публичной оферты конклюдентными действиями. Условия считаются заключенными с момента их принятия Пользователем. Использование Сервиса (включая осуществление доступа к Ресурсу) означает, что Пользователь полностью и безоговорочно принял Условия. Условия обязательны для исполнения Пользователем и Компанией с момента их заключения. Если Пользователь не согласен с Условиями, он не вправе использовать Сервис, в том числе осуществлять доступ к Ресурсу.

2.2. Условия могут быть изменены Компанией. Измененные Условия размещаются на Ресурсе. Используя Сервис после изменения Условий, Пользователь принимает Условия полностью и безоговорочно в измененном виде.

2.3. Для расторжения Условий Пользователю необходимо прекратить любое использование Сервиса, включая осуществление доступа к Ресурсу.

3. <b>ИСПОЛЬЗОВАНИЕ СЕРВИСА</b>

3.1. Необходимым и обязательным условием использования Сервиса является соблюдение Пользователем прав и законных интересов Компании, других Пользователей и иных третьих лиц, а также законодательства РФ.

3.2. Условием предоставления Сервиса является наличие рекламы на Ресурсе, если иное не предусмотрено Документами в отношении отдельного Сервиса. Пользователь осознает и соглашается, что Ресурс может содержать рекламу. При этом Пользователь не вправе размещать на Ресурсе рекламу и ссылки на рекламу, если иное не предусмотрено пользовательским соглашением отдельного Сервиса.

3.3. Сервис предназначен для личного некоммерческого использования, не связанного с осуществлением предпринимательской деятельности, если иное не предусмотрено Документами в отношении отдельного Сервиса.

3.4. Пользователь самостоятельно несет ответственность за все действия, совершаемые им на Ресурсе, за нарушение законодательства РФ, Условий и иных Документов. Компания не несет ответственности за любые виды убытков, произошедшие вследствие использования Сервиса.

3.5. Сервис предоставляется «как есть». Компания не гарантирует, что Сервис и результат его использования будет удовлетворять ожиданиям Пользователя. Пользователь принимает все последствия и самостоятельно несёт все возможные риски, связанные с использованием Сервиса.

3.6. Для предоставления отдельных Сервисов необходимы:

<ul>
  <li>направление Пользователю сервисных сообщений;</li>

  <li>применение рекомендательных технологий. Такие технологии применяются на Ресурсе в соответствии с Правилами применения рекомендательных технологий;</li>

  <li>обработка данных Пользователей. Такая обработка осуществляется в соответствии с условиями Политики конфиденциальности. В случае, если Пользователь предоставляет Компании данные третьих лиц, он гарантирует Компании наличие правового основания на их обработку в соответствии с условиями применимых Документов, а также уведомление таких лиц о соответствующей обработке Компанией и иными указанными в Документах лицами.</li>
</ul>

3.7. При использовании Пользователем соответствующего Сервиса для исполнения Условий необходима обработка Компанией данных Пользователя, включая их предоставление третьим лицам для последующей обработки в рамках следующих процессов:

<ul>
  <li>для сопровождения деятельности Компании провайдером услуг (ООО «РИХ», ООО «БРИ» по поручению Компании);</li>

  <li>для персонализации предложений ООО «РА «Индекс 20» (с последующим предоставлением ПАО Сбербанк, ООО «Облачные технологии»).</li>
</ul>

3.8. Для предоставления Сервисов на Ресурсе используются cookies (небольшие фрагменты данных о прошлых посещениях). Cookies необходимы в том числе для обеспечения функционирования Сервиса; персонализации Контента и иных предложений; проведения статистических и иных исследований использования Сервиса. Пользователь может просмотреть срок действия cookies, а также запретить их сохранение в настройках своего браузера. Сookies обрабатываются в том числе с использованием сервисов веб-аналитики, в частности, Статистика от SberAds, Яндекс.Метрика, Рейтинг Mail.ru, LiveInternet, Mediascope.

3.9. В случае отзыва Пользователем согласия на обработку персональных данных Компания вправе продолжить их обработку для исполнения Условий (или иных соглашений между Компании и Пользователем) или на иных основаниях, предусмотренных законодательством РФ.

3.10. Компания предоставляет Пользователю Сервис, позволяющий получать сообщения информационного, новостного, рекламного или иного характера при условии осуществления подписки в соответствии с Условиями рассылок.

4. <b>ИНОЕ</b>

4.1. Отношения Компании и Пользователя, в том числе предусмотренные Условиями, регулируются законодательством РФ.

4.2. Пользователь соглашается, что Компания вправе передать все свои права и обязанности по Документам, в том числе Условиям, другому лицу (например, при продаже Ресурса или иных изменениях).

4.3. Все споры, возникающие из Условий и/или связанные с Ресурсом и Контентом, могут быть переданы на разрешение суда после принятия Сторонами мер по досудебному урегулированию спора по истечении 30 календарных дней со дня направления первой претензии. Если спор не урегулирован в досудебном порядке согласно настоящему пункту, он может быть передан на разрешение суда по месту нахождения Компании.

4.4. По настоящим Условиям Компания предоставляет Сервис безвозмездно, поэтому нормы о защите прав потребителей не применяются.

4.5. Юридически значимые сообщения в адрес Компании направляются по адресу: 117105, г. Москва, Варшавское ш., д. 9, стр. 1.

<i>Редакция от 6 декабря 2024</i>
`;
