export default `
<i>Правила использования определяют запреты и ограничения, установленные в отношении использования Сервиса и/или Контента.</i>

1. <b>ОБЩИЕ ТРЕБОВАНИЯ И ОГРАНИЧЕНИЯ</b>

При использовании Сервиса необходимо соблюдать следующие требования и ограничения:

1.1. Конфиденциальность. При использовании Сервиса необходимо соблюдать конфиденциальность сведений, составляющих государственную, профессиональную, служебную, коммерческую, личную, семейную и иные охраняемые законом тайны, сведений о частной жизни лица, его персональных и иных данных.

1.2. Защита несовершеннолетних. На Ресурсе запрещены к распространению информация и материалы, способные причинить вред здоровью и развитию несовершеннолетних или представляющую угрозу их жизни. В частности, материалы и информация:

<ul>
  <li>побуждающие детей к совершению действий, представляющих угрозу их жизни и/или здоровью (в том числе к причинению вреда своему здоровью, самоубийству, либо жизни и/или здоровью иных лиц, либо направленная на склонение или иное вовлечение детей в совершение таких действий);</li>

  <li>способные вызвать у детей желание употребить наркотические средства, психотропные и/или одурманивающие вещества, табачные изделия, никотинсодержащую продукцию, алкогольную и спиртосодержащую продукцию, принять участие в азартных играх, заниматься проституцией, бродяжничеством или попрошайничеством;</li>

  <li>обосновывающие или оправдывающие допустимость насилия и/или жестокости, либо побуждающие осуществлять насильственные действия по отношению к людям или животным;</li>

  <li>содержащие изображение или описание сексуального насилия;</li>

  <li>отрицающие семейные ценности и формирующие неуважение к родителям и/или другим членам семьи;</li>

  <li>пропагандирующие либо демонстрирующие нетрадиционные сексуальные отношения и/или предпочтения;</li>

  <li>пропагандирующие педофилию;</li>

  <li>способные вызвать у детей желание сменить пол и/или пропагандирующие смену пола;</li>

  <li>оправдывающие противоправное поведение;</li>

  <li>содержащие нецензурную брань;</li>

  <li>имеющие порнографический характер;</li>

  <li>направленные на склонение или иное вовлечение несовершеннолетних в совершение противоправных действий, представляющих угрозу для их жизни и (или) здоровья либо для жизни и (или) здоровья иных лиц;</li>

  <li>содержащие сведения о несовершеннолетнем, пострадавшем в результате противоправных действий (бездействия);</li>

  <li>содержащие порнографические изображения несовершеннолетних и (или) объявлений о привлечении несовершеннолетних в качестве исполнителей для участия в зрелищных мероприятиях порнографического характера;</li>

  <li>содержащиеся в информационной продукции, произведенной иностранным агентом;</li>

  <li>содержащие сведения, позволяющие получить доступ к указанным выше информации или материалам.</li>
</ul>

1.3. Безопасность для жизни и здоровья. На Ресурсе запрещено распространять информацию и материалы, которые могут представлять угрозу или причинить вред жизни и здоровью людей либо привести к нарушению прав и законных интересов граждан и иных лиц. В частности, материалы и информацию:

<ul>
  <li>содержащие сведения о способах, методах разработки, изготовления и использования наркотических средств, психотропных веществ и их прекурсоров, новых потенциально опасных психоактивных веществ, местах их приобретения, способах и местах культивирования наркосодержащих растений;</li>

  <li>содержащие сведения о способах совершения самоубийства, а также призывов к совершению самоубийства;</li>

  <li>содержащие предложения о розничной продаже дистанционным способом алкогольной продукции, и/или спиртосодержащей пищевой продукции, и/или этилового спирта, и/или спиртосодержащей непищевой продукции, розничная продажа которой ограничена или запрещена законодательством о государственном регулировании производства и оборота этилового спирта, алкогольной и спиртосодержащей продукции и об ограничении потребления алкогольной продукции;</li>

  <li>содержащие недостоверную общественно значимую информацию, распространяемую под видом достоверных сообщений, создающую угрозу причинения вреда жизни и (или) здоровью граждан, имуществу, угрозу массового нарушения общественного порядка и (или) общественной безопасности либо угрозу создания помех функционированию или прекращения функционирования объектов жизнеобеспечения, транспортной или социальной инфраструктуры, кредитных организаций, объектов энергетики, промышленности или связи;</li>

  <li>содержащие призывы к совершению уголовно наказуемых деяний;</li>

  <li>представляющие деятельность по организации и проведению азартных игр и лотерей с использованием сети Интернет или иных средств связи;</li>

  <li>содержащие сведения, позволяющие получить доступ к указанным выше информации или материалам.</li>
</ul>

1.4. Соблюдение общественного порядка. На Ресурсе запрещено распространять информацию и материалы, которые нарушают или могут представлять угрозу публичному и/или общественному порядкам РФ. В частности, материалы и информацию:

<ul>
  <li>содержащие призывы к массовым беспорядкам;</li>

  <li>содержащие призывы к осуществлению экстремистской деятельности;</li>

  <li>содержащие призывы к участию в массовых (публичных) мероприятиях, проводимых с нарушением установленного порядка;</li>

  <li>содержащие сведения об иностранной или международной неправительственной организации, деятельность которой признана нежелательной на территории РФ;</li>

  <li>содержащие материалы иностранной или международной неправительственной организации, деятельность которой признана нежелательной на территории РФ;</li>

  <li>содержащие публичные призывы к осуществлению террористической деятельности или публично оправдывающих терроризм, иного экстремистского характера;</li>

  <li>выражающие явное неуважение к обществу, государству, официальным государственным символам РФ, Конституции РФ или органам, осуществляющим государственную власть в РФ;</li>

  <li>разъясняющие порядок изготовления, применения или иного использования взрывчатых веществ или иного оружия;</li>

  <li>нарушающие запреты и ограничения, предусмотренные законодательством РФ о референдуме и выборах;</li>

  <li>содержащие сведения, позволяющие получить доступ к указанным выше информации или материалам.</li>
</ul>

1.5. Этичность. На Ресурсе запрещено размещать информацию и материалы, противоречащие нормам поведения, морали, нравственности, уважения, добросовестности и деловой этике. В частности, материалы и информацию:

<ul>
  <li>пропагандирующие порнографию, насилие и жестокость;</li>

  <li>содержащие нецензурные, бранные слова и словосочетания, оскорбления в адрес физических лиц или организаций;</li>

  <li>распространяемые с целью опорочить гражданина или отдельные категории граждан по признакам пола, возраста, расовой или национальной принадлежности, языка, отношения к религии, профессии, места жительства и работы, а также в связи с их политическими убеждениями;</li>

  <li>выраженные в неприличной форме, которые оскорбляют человеческое достоинство и общественную нравственность;</li>

  <li>нарушающие честь, достоинство, деловую репутацию граждан и деловую репутацию организаций, в том числе Компании;</li>

  <li>содержащие сведения, позволяющие получить доступ к указанным выше информации или материалам.</li>
</ul>

1.6. Техническая безопасность. На Ресурсе запрещено размещать информацию и материалы, которые могут причинить ущерб или иные негативные последствия Компании, Ресурсу, проектам, продуктам и сервисам Пользователей и третьих лиц. В частности, материалы и информацию:

<ul>
  <li>содержащие вирусы или другие компьютерные коды, файлы или программы, предназначенные для нарушения, уничтожения либо ограничения функциональности любого продукта, сайта, сервиса, программы и оборудования;</li>

  <li>предназначенные для несанкционированного доступа к любым продуктам, сайтам, услугам, программам и оборудованию;</li>

  <li>направленные на нарушение нормального функционирования Ресурсов, проектов, продуктов и сервисов Пользователей и третьих лиц;</li>

  <li>содержащие сведения, позволяющие получить доступ к указанным выше информации или материалам.</li>
</ul>

1.7. Законность. На Ресурсе запрещено размещать информацию и материалы, нарушающие права Компании, других Пользователей и иных третьих лиц, а также законодательство РФ.

2. <b>ПРАВИЛА КОММЕНТИРОВАНИЯ</b>

При размещении на Ресурсе комментариев, отзывов, рецензий, необходимо соблюдать общие требования и ограничения, указанные в разделе 1 Правил использования, а также:

2.1. Уважать других Пользователей и воздерживаться от оскорбительных, грубых, угрожающих и агрессивных комментариев.

2.2. Проявлять терпимость к иным мнениям и убеждениям.

2.3. Соблюдать культуру речи и не использовать ненормативную лексику, бранные слова и ругательства, а также следовать правилам русского языка.

2.4. Следовать тематике Ресурса и дискуссии.

3. <b>ПРАВИЛА РАЗМЕЩЕНИЯ КОНТЕНТА</b>

При размещении Контента необходимо соблюдать общие требования и ограничения, указанные в разделе 1 Правил использования, а также запреты и ограничения, приведенные ниже:

3.1. Гарантировать, что размещаемый Контент не нарушает прав и законных интересов третьих лиц, Условия, в том числе Правила использования, а также законодательство РФ;

3.2. Самостоятельно нести ответственность за содержание и использование Контента и все связанные с этим последствия.

4. <b>ТРЕБОВАНИЯ И ОГРАНИЧЕНИЯ К ИСПОЛЬЗОВАНИЮ МАТЕРИАЛОВ</b>

При использовании любых материалов, размещенных на Ресурсе, в том числе текстов, статей, изображений, видео, музыки, необходимо соблюдать общие требования и ограничения, указанные в разделе 1 Правил использования, а также запреты и ограничения, приведенные ниже:

4.1. На Ресурсе запрещено совершать действия, приводящие к нарушению прав Компании, других Пользователей или третьих лиц на объекты интеллектуальной собственности и средства индивидуализации.

4.2. Любые материалы, размещенные не Ресурсе, могут быть загружены (скачены) только для личного использования.

4.3. Запрещается вносить изменения, публиковать, передавать третьим лицам, участвовать в продаже или уступке, создавать производные продукты или иным образом использовать, частично или полностью, содержание Ресурса, в том числе любые материалы (тексты, статьи, изображения, видео, музыку) (исключение составляют случаи использования размещенных текстовых материалов в объеме не более 300 знаков без учета знаков препинания с обязательным указанием правообладателя и источника заимствования). Использование любых фотоматериалов и графических изображений не допускается без согласия Компании.

4.4. Запрещается использовать автоматизированные скрипты (программы, боты, краулеры) для сбора информации на Ресурсе и/или взаимодействия с Ресурсом без специального на то разрешения Компании.

<i>Редакция от 6 декабря 2024</i>
`;
